import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  IconButton,
  Toolbar,
  Typography,
  AppBar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons/";
import { Link } from "react-router-dom";
import { useAuth0 } from "../utils/react-auth0-spa";
import { config } from "../constants";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const { isAuthenticated, logout, loading, user } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  if (!isAuthenticated) {
    return null;
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h4"
          className={classes.title}
          component={Link}
          to="/"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          ToDo
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            {loading ? (
              <AccountCircle />
            ) : (
              <Avatar alt={user.name} src={user.picture} />
            )}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/profile" onClick={handleClose}>
              Profile
            </MenuItem>

            <MenuItem onClick={() => logout({ returnTo: config.url.APP_URL })}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
