import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddIcon from "@material-ui/icons/Add";
import { useListContext } from "../utils/ListProvider";

export function NewListModal() {
  const { newList } = useListContext();

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const handleFailure = () => {
    handleOpen();
    setInputValue(inputValue);
  };

  const handleAdd = async () => {
    handleClose();
    await newList(inputValue, handleFailure);
  };

  const onKeyDown = async (event) => {
    // https://stackoverflow.com/questions/33211672/how-to-submit-a-form-using-enter-key-in-react-js
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      await handleAdd();
    }
  };

  return (
    <>
      <ListItem button divider onClick={handleOpen}>
        <ListItemText primary="New List" />
        <ListItemSecondaryAction>
          <IconButton onClick={handleOpen}>
            <AddCircleIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="New List"
        aria-describedby="Dialog for creating a new list"
      >
        <DialogTitle id="form-dialog-title">Add New List</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            autoComplete="off"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={onKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
