import React, { useState, useContext } from "react";
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export const ErrorContext = React.createContext();
export const useError = () => useContext(ErrorContext);
export const ErrorProvider = (props) => {
  const [currentMessage, setCurrentMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [criticalErrorFlag, setCriticalErrorFlag] = useState(false);

  const showError = (message) => {
    setCurrentMessage(message);
    setOpen(true);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleUnrecoverableError = async (message, pageTimeoutSeconds = 10) => {
    setOpen(false);
    setCurrentMessage(message);
    setCriticalErrorFlag(true);
    await new Promise((r) => setTimeout(r, pageTimeoutSeconds * 1000));
    refreshPage();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <ErrorContext.Provider value={{ showError, handleUnrecoverableError }}>
        {props.children}
      </ErrorContext.Provider>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          {currentMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={criticalErrorFlag}
        aria-labelledby="Critical error"
        aria-describedby="A critical error has been encountered"
      >
        <DialogTitle>A critical error has occured.</DialogTitle>
        <DialogContent>
          <DialogContentText>{currentMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={refreshPage} color="primary" autoFocus>
            Refresh
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
