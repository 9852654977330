import React from "react";
import { EditListModal } from "./EditListModal.js";
import { DeleteListModal } from "./DeleteListModal.js";
import { useListContext } from "../utils/ListProvider";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  title: {
    flexGrow: 1,
  },
}));

export const ListHeader = () => {
  const { currentList } = useListContext();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        {currentList.name}
      </Typography>
      <EditListModal />
      <DeleteListModal />
    </div>
  );
};
