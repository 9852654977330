import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "../utils/react-auth0-spa";
import { Link } from "react-router-dom";
import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

const useStyles = makeStyles({
  profileImage: {
    marginTop: "30px",
    marginBottom: "20px",
    textAlign: "center",
  },
  media: {
    height: "100%",
  },
});

export const ProfilePage = () => {
  const { loading, user, logout } = useAuth0();
  const classes = useStyles();

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={classes.profileImage} alt="Profile Image">
        <img src={user.picture} alt="User profile" />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">{user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">{user.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button size="large" component={Link} to="/">
        Back to App
      </Button>
      <Button size="large" onClick={logout}>
        Logout
      </Button>
    </>
  );
};
