import React, { useState, useEffect, useContext, createContext } from "react";
import { useAuth0 } from "./react-auth0-spa";
import { config } from "../constants.js";
import { useError } from "./ErrorProvider";

const API_PATH = `${config.url.API_URL}/list`;

function ApiError(problem) {
  this.name = "ApiError";
  this.message = problem.title;
  this.problem = problem;
}
ApiError.prototype = new Error();

export const ListContext = createContext();
export const useListContext = () => useContext(ListContext);
export const ListProvider = (props) => {
  const { getTokenSilently } = useAuth0();
  const { showError, handleUnrecoverableError } = useError();

  const [allListNames, setAllListNames] = useState([]);
  const [currentList, setCurrentList] = useState(null);

  const setCurrentListByName = async (listName) => {
    if (listName == null) {
      setCurrentList(null);
      return;
    }

    const list = await callGetEndpoint(listName);
    setCurrentList(list);
  };

  useEffect(() => {
    const fn = async () => {
      var allLists = await callGetAllEndpoint();
      setAllListNames(allLists.map((item) => item.name));
      setCurrentListByName(allLists.length > 0 ? allLists[0].name : null);
    };
    fn();
    // eslint-disable-next-line
  }, []);

  const toggleCompleteByTitleFactory = (itemTitle) => async () => {
    const itemIndex = currentList.itemList.findIndex(
      (item) => item.title === itemTitle
    );
    var listCopy = Object.assign({}, currentList);
    listCopy.itemList[itemIndex].isComplete = !listCopy.itemList[itemIndex]
      .isComplete;
    setCurrentList(listCopy);
    await callUpdateEndpoint(listCopy);
  };

  const toggleStarredByTitleFactory = (itemTitle) => async () => {
    const itemIndex = currentList.itemList.findIndex(
      (item) => item.title === itemTitle
    );
    const listCopy = { ...currentList };
    listCopy.itemList[itemIndex].isStarred = !listCopy.itemList[itemIndex]
      .isStarred;
    setCurrentList(listCopy);
    await callUpdateEndpoint(listCopy);
  };

  const updateItemByTitle = (newItem) => {
    const itemIndex = currentList.itemList.findIndex(
      (item) => item.title === newItem.title
    );

    var newItemList = Object.assign([], currentList.itemList);
    newItemList.splice(itemIndex, 1, newItem);

    const newCurrentList = {
      ...currentList,
      itemList: newItemList,
    };

    setCurrentList(newCurrentList);
    callUpdateEndpoint(newCurrentList);
  };

  const deleteItemByTitleFactory = (itemTitle) => async () => {
    const itemIndex = currentList.itemList.findIndex(
      (item) => item.title === itemTitle
    );
    var listCopy = Object.assign({}, currentList);
    listCopy.itemList.splice(itemIndex, 1);
    setCurrentList(listCopy);

    await callUpdateEndpoint(listCopy);
  };

  const addItemToCurrentList = async (title, onFailure = () => {}) => {
    if (currentList.itemList.map((item) => item.title).includes(title)) {
      showError(
        `An item called ${title} already exists. Item names must be unique.`
      );
      onFailure();
      return;
    }

    var listCopy = Object.assign({}, currentList);
    const newItem = {
      title: title,
      details: "",
      isComplete: false,
      createdDateUtc: new Date(),
      dueDateUtc: null,
    };
    listCopy.itemList = [newItem].concat(listCopy.itemList);
    setCurrentList(listCopy);

    await callUpdateEndpoint(listCopy);
  };

  const newList = async (name, onFailure = () => {}) => {
    if (allListNames.includes(name)) {
      showError(`List with name '${name}' already exists.`);
      onFailure();
      return;
    }

    const list = await callAddEndpoint(
      {
        name: name,
        itemList: [],
      }
    );
    setAllListNames([name].concat(allListNames));
    setCurrentList(list);
  };

  const addNewListNameToAllLists = (listName, index = 0) => {
    var allListNamesCopy = Object.assign([], allListNames);
    allListNamesCopy.splice(index, 0, listName);
    setAllListNames(allListNamesCopy);
  };

  const removeListNameFromAllLists = (index) => {
    var allListNamesCopy = Object.assign([], allListNames);
    allListNamesCopy.splice(index, 1);
    setAllListNames(allListNamesCopy);
  };

  const changeCurrentListName = async (newName) => {
    const index = allListNames.findIndex((name) => name === currentList.name);
    removeListNameFromAllLists(index);
    addNewListNameToAllLists(newName, index);

    const newList = await callAddEndpoint({ ...currentList, name: newName });
    await callDeleteEndpoint(currentList.name);
    setCurrentList(newList);
  };

  const deleteCurrentList = async () => {
    const index = allListNames.findIndex((name) => name === currentList.name);
    removeListNameFromAllLists(index);
    await callDeleteEndpoint(currentList.name);

    const eligibleNewNames = allListNames.filter(
      (name) => name !== currentList.name
    );
    setCurrentListByName(eligibleNewNames[0]);
  };

  const callGetAllEndpoint = async () => {
    const response = await makeAuthedApiCall(`${API_PATH}`, "GET");
    return await response.json();
  };

  const callGetEndpoint = async (name) => {
    const response = await makeAuthedApiCall(`${API_PATH}/${name}`, "GET");
    const list = listStringsToDates(await response.json());
    return list;
  };

  const callAddEndpoint = async (list) => {
    const stringList = listDatesToStrings(list);
    const response = await makeAuthedApiCall(API_PATH, "POST", stringList);
    return await response.json();
  };

  const callUpdateEndpoint = async (list) => {
    const stringList = listDatesToStrings(list);
    return await makeAuthedApiCall(API_PATH, "PUT", stringList);
  };

  const callDeleteEndpoint = async (listName) => {
    return await makeAuthedApiCall(`${API_PATH}/${listName}`, "DELETE");
  };

  const listStringsToDates = (list) => {
    return {
      ...list,
      itemList: list.itemList.map((item) => {
        item.dueDateUtc = item.dueDateUtc ? new Date(item.dueDateUtc) : null;
        item.createdDateUtc = new Date(item.createdDateUtc);
        return item;
      }),
    };
  };

  const listDatesToStrings = (list) => {
    return {
      ...list,
      itemList: list.itemList.map((item) => ({
        ...item,
        dueDateUtc: item.dueDateUtc ? item.dueDateUtc.toISOString() : null,
        createdDateUtc: item.createdDateUtc.toISOString(),
      })),
    };
  };

  const makeAuthedApiCall = async (path, method, data = undefined) => {
    const token = await getTokenSilently();

    const request = {
      method: method,
      headers: { Authorization: `Bearer ${token}` },
    };

    if (data) {
      request.body = JSON.stringify(data);
      request.headers["Content-Type"] = "application/json";
    }

    const response = await fetch(path, request);

    if (!(await response.ok)) {
      // TODO: Elegantly handle API errors and revert state on failure.
      await handleUnrecoverableError(
        `An HTTP ${response.status} API error occurred.`,
        60
      );
      return;
    }

    return response;
  };

  return (
    <ListContext.Provider
      value={{
        allListNames,
        currentList,
        setCurrentListByName,
        toggleCompleteByTitleFactory,
        toggleStarredByTitleFactory,
        deleteItemByTitleFactory,
        addItemToCurrentList,
        newList,
        changeCurrentListName,
        deleteCurrentList,
        updateItemByTitle,
      }}
    >
      {props.children}
    </ListContext.Provider>
  );
};
