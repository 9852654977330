import React from "react";
import NavBar from "./components/NavBar";
import { Router, Switch, Route } from "react-router-dom";
import history from "./utils/history";
import { ListPage } from "./pages/ListPage";
import { ProfilePage } from "./pages/ProfilePage";
import { useAuth0 } from "./utils/react-auth0-spa";
import { Container, LinearProgress } from "@material-ui/core";
import { DialogProvider } from "./utils/DialogProvider";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ListProvider } from "./utils/ListProvider";

export const App = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (loading) {
    return <LinearProgress />;
  }

  const pageIfNoAuth = async () => {
    await loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
    return <div>Please Log in</div>;
  };

  return isAuthenticated ? (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ListProvider>
          <DialogProvider>
            <Router history={history}>
              <header>
                <NavBar />
              </header>

              <Container maxWidth="md">
                <Switch>
                  <Route path="/" component={ListPage} exact />
                  <Route path="/profile" component={ProfilePage} />
                </Switch>
              </Container>
            </Router>
          </DialogProvider>
        </ListProvider>
      </MuiPickersUtilsProvider>
    </>
  ) : (
    pageIfNoAuth()
  );
};
