import React from "react";
import { ToDoList } from "../components/ToDoList";
import { ListHeader } from "../components/ListHeader";
import { useListContext } from "../utils/ListProvider";
import { Typography } from "@material-ui/core";

export const ListView = () => {
  const { currentList } = useListContext();
  return currentList == null ? (
    <Typography align="center" variant="h5" style={{ marginTop: "30px" }}>
      Select or create a new list from the sidebar
    </Typography>
  ) : (
    <>
      <ListHeader />
      <ToDoList />
    </>
  );
};
