import React, { useState, useContext, createContext } from "react";
import { ListItemDetailDialog } from "../dialogs/ListItemDetailDialog";

const dialogs = {
  ListItemDetailDialog: ListItemDetailDialog,
};

export const DialogContext = createContext();
export const useDialog = () => useContext(DialogContext);
export const DialogProvider = (props) => {
  // To add a new dialog, add to the state object and the dialogs object.
  const [dialogState, setDialogState] = useState({
    ListItemDetailDialog: {
      open: false,
      props: {},
    },
  });

  const dialogIsOpen = (dialogName) => dialogState[dialogName].open;
  const dialogProps = (dialogName) => dialogState[dialogName].props;

  const closeDialogFactory = (dialogName) => () => {
    setDialogState({
      ...dialogState,
      [dialogName]: {
        open: false,
        props: dialogState[dialogName].props,
      },
    });
  };

  const openDialog = (dialogName, props = {}) => {
    setDialogState({
      ...dialogState,
      [dialogName]: {
        open: true,
        props: props,
      },
    });
  };

  return (
    <>
      <DialogContext.Provider value={{ openDialog }}>
        {props.children}
      </DialogContext.Provider>
      {Object.entries(dialogs).map(([name, component]) =>
        React.createElement(
          component,
          {
            key: name,
            open: dialogIsOpen(name),
            closeDialog: closeDialogFactory(name),
            props: dialogProps(name),
          },
          null
        )
      )}
    </>
  );
};
