import React from "react";
import { Grid } from "@material-ui/core";

import { ListView } from "../components/ListView";
import { SideBar } from "../components/SideBar";

export const ListPage = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SideBar />
      </Grid>
      <Grid item xs={8}>
        <ListView />
      </Grid>
    </Grid>
  );
};
