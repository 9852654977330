import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { useListContext } from "../utils/ListProvider";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  title: {
    flexGrow: 1,
  },
}));

const emptyListItem = {
  title: "",
  details: "",
  isComplete: false,
  dueDateUtc: new Date(),
};

export const ListItemDetailDialog = ({ open, closeDialog, props }) => {
  const classes = useStyles();
  const { updateItemByTitle } = useListContext();

  const [listItem, setListItem] = useState(emptyListItem);

  useEffect(() => {
    setListItem(props.listItem ? props.listItem : emptyListItem);
  }, [setListItem, props]);

  const handleInputChange = (e) => {
    const target = e.target;
    setListItem({
      ...listItem,
      [target.name]: target.value,
    });
  };

  const handleDateChange = (date) => {
    setListItem({
      ...listItem,
      dueDateUtc: date,
    });
  };

  const handleToggleCompleted = () => {
    setListItem({
      ...listItem,
      isComplete: !listItem.isComplete,
    });
  };

  const handleClose = () => {
    closeDialog();
    updateItemByTitle(listItem);
    setListItem(emptyListItem);
  };

  const handleSave = async () => {
    handleClose(); // saves on close
  };

  const onKeyDown = async (event) => {
    // https://stackoverflow.com/questions/33211672/how-to-submit-a-form-using-enter-key-in-react-js
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      await handleSave();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="Edit List"
      aria-describedby="Dialog for editing a list"
    >
      <DialogTitle>
        <div className={classes.header}>
          <span className={classes.title}>Item Details</span>
          <FormControlLabel
            control={
              <Checkbox
                label="Complete"
                checked={listItem.isComplete}
                onChange={handleToggleCompleted}
              />
            }
            label="Complete"
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          variant="filled"
          name="title"
          label="Title"
          type="text"
          autoComplete="off"
          fullWidth
          value={listItem.title}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
        />
        <TextField
          margin="normal"
          multiline
          rows={2}
          variant="filled"
          name="details"
          label="Details"
          type="text"
          autoComplete="off"
          fullWidth
          value={listItem.details ? listItem.details : ""}
          onChange={handleInputChange}
        />
        <KeyboardDateTimePicker
          margin="normal"
          clearable
          disablePast
          fullWidth
          inputVariant="filled"
          label="Due Date"
          value={listItem.dueDateUtc}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
