import React from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useListContext } from "../utils/ListProvider";
import { NewListModal } from "./NewListModal";

export const SideBar = () => {
  const { setCurrentListByName, allListNames, currentList } = useListContext();

  return (
    <List>
      <NewListModal />
      {allListNames.map((name, index) => (
        <ListItem
          key={index}
          selected={currentList ? name === currentList.name : false}
          button
          onClick={() => setCurrentListByName(name)}
        >
          <ListItemText primary={name} />
        </ListItem>
      ))}
    </List>
  );
};
