import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import format from "date-fns/format";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { useListContext } from "../utils/ListProvider.js";
import { ListItemControls } from "./ListItemControls.js";

const useStyles = makeStyles(() => ({
  listItemSecondaryAction: {
    visibility: "hidden",
  },
  listItem: {
    "&:hover $listItemSecondaryAction": {
      visibility: "inherit",
    },
  },
}));

export const ToDoItem = ({ item }) => {
  const classes = useStyles();
  const { toggleCompleteByTitleFactory } = useListContext();

  return (
    <ListItem
      button
      onClick={toggleCompleteByTitleFactory(item.title)}
      classes={{
        container: classes.listItem,
      }}
    >
      <ListItemIcon>
        <Checkbox
          checked={item.isComplete}
          disableRipple
          inputProps={{ "aria-labelledby": item.title }}
        />
      </ListItemIcon>

      <ListItemText
        primary={item.title}
        secondary={
          item.dueDateUtc
            ? format(item.dueDateUtc, "'Due at' K:mm a 'on' E do MMM")
            : null
        }
        secondaryTypographyProps={
          item.dueDateUtc < new Date() ? { style: { color: "red" } } : null
        }
      />

      <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
        <ListItemControls item={item} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
