import React, { useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  IconButton,
  Input,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { useListContext } from "../utils/ListProvider.js";

export const ListItemInput = () => {
  const { addItemToCurrentList } = useListContext();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  const handleAddItem = async () => {
    if (inputValue === "") {
      return;
    }

    await addItemToCurrentList(inputValue);
    setInputValue("");
  };

  const onKeyDown = async (event) => {
    // https://stackoverflow.com/questions/33211672/how-to-submit-a-form-using-enter-key-in-react-js
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      await handleAddItem();
    }
  };

  return (
    <ListItem>
      <ListItemIcon>
        <Checkbox checked={false} disabled />
      </ListItemIcon>
      <Input
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        placeholder="Add an Item"
        disableUnderline={true}
      />
      <ListItemSecondaryAction>
        <IconButton
          type="submit"
          aria-label="create item"
          onClick={handleAddItem}
        >
          <AddCircleIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
