import React from "react";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import { useListContext } from "../utils/ListProvider";
import { useDialog } from "../utils/DialogProvider";

export const ListItemControls = ({ item }) => {
  const { deleteItemByTitleFactory } = useListContext();

  const { openDialog } = useDialog();

  const openItemDetailsDialog = () => {
    openDialog("ListItemDetailDialog", { listItem: item });
  };

  return (
    <>
      <IconButton aria-label="more details" onClick={openItemDetailsDialog}>
        <MoreHorizIcon />
      </IconButton>

      <IconButton
        aria-label="delete"
        onClick={deleteItemByTitleFactory(item.title)}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};
