import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import SaveIcon from "@material-ui/icons/Save";
import { useListContext } from "../utils/ListProvider";

export function EditListModal() {
  const { currentList, changeCurrentListName } = useListContext();

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setInputValue(currentList.name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const handleSave = async () => {
    handleClose();
    await changeCurrentListName(inputValue);
  };

  const onKeyDown = async (event) => {
    // https://stackoverflow.com/questions/33211672/how-to-submit-a-form-using-enter-key-in-react-js
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      await handleSave();
    }
  };

  return (
    <>
      <IconButton edge="end" aria-label="settings" onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="Edit List"
        aria-describedby="Dialog for editing a list"
      >
        <DialogTitle id="form-dialog-title">Edit List</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            autoComplete="off"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={onKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
