import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  Typography,
} from "@material-ui/core";

import { ToDoItem } from "./ToDoItem.js";
import { useListContext } from "../utils/ListProvider.js";
import { ListItemInput } from "./ListItemInput.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export const ToDoList = () => {
  const { currentList } = useListContext();

  const classes = useStyles();

  const [openCompleted, setCompleted] = useState(false);
  const handleCompletedToggle = () => {
    setCompleted(!openCompleted);
  };

  return (
    <List className={classes.root}>
      <ListItemInput />
      {currentList.itemList
        .filter((item) => !item.isComplete)
        .sort((firstEl, secondEl) => secondEl.isStarred - firstEl.isStarred)
        .map((item) => (
          <ToDoItem item={item} key={item.title} />
        ))}
      <Divider />
      <ListItem button onClick={handleCompletedToggle} key="completed-title">
        <ListItemText
          primary={<Typography variant="h6">Completed</Typography>}
        />
        {openCompleted ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCompleted} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {currentList.itemList
            .filter((item) => item.isComplete)
            .map((item) => (
              <ToDoItem item={item} key={item.title} />
            ))}
        </List>
      </Collapse>
      <Divider />
    </List>
  );
};
